/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { Box, Collapse, List, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import NavItem from "./NavItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const renderNavItems = ({ depth = 0, items, pathname, drawerOpen }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({
      acc,
      item,
      pathname,
      depth,
      drawerOpen
    }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth, drawerOpen }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;
    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        hidden={item.hidden}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        count={item.count}
        drawerOpen={drawerOpen}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          drawerOpen,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        hidden={item.hidden}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        count={item.count}
        drawerOpen={drawerOpen}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, icon, hidden, drawerOpen, ...other } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{
      alignItems: "center",
      display: hidden ? "none" : "inline",
      }}
    >
    { !!title && (
    <ListItemButton
      onClick={handleClick}
      sx={{
        justifyContent: drawerOpen ? "flex-start" : "center",
        backgroundColor: "#0095FF0D",
        py: 1.5,
        px: 1,
      }}
    >
      <ListItemIcon
        sx={{
          mr: drawerOpen ? 1.5 : 0,
          justifyContent: "center",
        }}
      >
        {icon}
      </ListItemIcon>
      {drawerOpen ? (
        <>
          <Typography
            sx={{
              color: "text.secondary",
              fontWeight: "fontWeightMedium",
              justifyContent: "flex-start",
              textAlign: "left",
              textTransform: "none",
              width: "100%",
            }}
            fontWeight="fontWeightMedium"
          >
            {title}
          </Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </>
      ) : null}
    </ListItemButton>
    )}
    <Collapse in={open} timeout="auto" unmountOnExit>
    <List
      {...other}
      sx={{ p: 0 }}
    >
      {renderNavItems({
        items,
        pathname,
        drawerOpen,
      })}
    </List>
    </Collapse>
    </Box>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  hidden: PropTypes.bool,
  count: PropTypes.number,
  drawerOpen: PropTypes.bool,
};

export default NavSection;
