import axios from "axios";
// import { toast } from "react-hot-toast";

const apiBaseUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = apiBaseUrl;

// const token = localStorage.getItem("access");
// axios.defaults.headers.common.Authorization = `Bearer ${token}`; // for all requests

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access"); // Fetch the latest token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Base methods

async function destroy(url) {
  return axios.delete(url).then((res) => (res.data));
}

async function get(url, data = null) {
  console.log("url", apiBaseUrl + url);

  if (data && data.params) {
    // setting offset for page
    if (data.params.page && data.params.limit) {
      data.params.offset = (data.params.page - 1) * data.params.limit;
    }
    delete data.params.page;
  }

  return axios.get(url, data).then((res) => (res.data));
}

async function patch(url, data) {
  return axios.patch(url, data).then((res) => (res.data));
}

async function post(url, data) {
  return axios.post(url, data).then((res) => (res.data));
}

async function postFile(url, data) {
  return axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => (res.data));
}

const functions = {
    delete: destroy,
    get,
    patch,
    post,
    postFile,
};

export default functions;
