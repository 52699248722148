export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

export const MESSAGES = {
  API_ERROR: "An error occured while saving the changes.",
  API_SUCCESS: (model) => `${model} saved!`,
  API_FETCH_ERROR: "An error occured while fetching data."
};
