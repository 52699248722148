import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./apiConfig";

export const trainApi = createApi({
  reducerPath: "trainApi",
  baseQuery,
  tagTypes: ["Train", "Arrival", "Activity", "Detail", "Delivery"],
  endpoints: (builder) => ({
    // Fetch all trains
    fetchTrains: builder.query({
      query: (params) => ({ url: "coal/train/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Train", id })),
            { type: "Train", id: "LIST" },
            ]
        : [{ type: "Train", id: "LIST" }]),
    }),
    // Fetch train arrivals
    fetchTrainArrivals: builder.query({
      query: (params) => ({ url: "coal/train/arrivals/", params }),
      providesTags: (result) =>
        (result?.arrivals
        ? [
            ...result.arrivals.map(({ month }) => ({ type: "Arrival", id: month })),
            { type: "Arrival", id: "LIST" },
            ]
        : [{ type: "Arrival", id: "LIST" }]),
      invalidatesTags: () => [{ type: "Arrival", id: "LIST" }],
    }),
    // Fetch train deliveries
    fetchTrainDeliveries: builder.query({
      query: (params) => ({ url: "coal/train/deliveries/", params }),
    }),
    // Fetch train by ID
    fetchTrainById: builder.query({
        query: (params) => `coal/train/${params.id}/`,
        providesTags: (result) => [{ type: "Train", id: result.id }],
    }),
    // Fetch train detail by ID
    fetchTrainDetailById: builder.query({
      query: (id) => `coal/train/${id}/detail/`,
      providesTags: (result) => [{ type: "Detail", id: result.id }],
    }),
    // Fetch train activity by ID
    fetchTrainActivityById: builder.query({
      query: (id) => `coal/train/${id}/activity/`,
      providesTags: (result) => [{ type: "Activity", id: result.id }],
    }),
    // Create a new train
    createTrain: builder.mutation({
      query: (data) => ({ url: "coal/train/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "Train", id: result.id }],
      invalidatesTags: () => [
        { type: "Train", id: "LIST" },
        { type: "Arrival", id: "LIST" }
      ],

    }),
    // Update train by ID
    updateTrainById: builder.mutation({
      query: (params) => ({ url: `coal/train/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "Train", id: result.id }],
      invalidatesTags: (result) => [
        { type: "Train", id: result.id },
        { type: "Activity", id: result.id },
        { type: "Arrival", id: "LIST" },
      ],
    }),
    // Generate trains
    generateTrains: builder.mutation({
      query: (params) => ({ url: "coal/train/generate/", params }),
      invalidatesTags: () => [
        { type: "Train", id: "LIST" },
        { type: "Arrival", id: "LIST" },
        { type: "Activity", id: "LIST" },
      ],
    }),
    // Create certificate for train
    createTrainCertificate: builder.mutation({
      query: (data) => {
        const body = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          body.append(key, value);
        });

        return ({
        url: "coal/certificate/",
        method: "POST",
        formData: true,
        body,
      });
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Train", id: arg.train },
        { type: "Detail", id: arg.train },
      ],
    }),
  }),
});

// Destructure the generated hooks for the trainApi slice
export const {
  useFetchTrainsQuery,
  useFetchTrainArrivalsQuery,
  useFetchTrainDeliveriesQuery,
  useFetchTrainByIdQuery,
  useFetchTrainDetailByIdQuery,
  useFetchTrainActivityByIdQuery,
  useCreateTrainMutation,
  useUpdateTrainByIdMutation,
  useGenerateTrainsMutation,
  useCreateTrainCertificateMutation,
} = trainApi;

export default trainApi;
