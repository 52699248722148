import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-hot-toast";

export const handleQueryError = async (errorMessage, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    // handle error here, dispatch toast message
    console.log("server error:", error);
    if (errorMessage) {
      toast.error(`Server error: ${errorMessage}`);
    } else {
      toast.error("Server error.");
    }
  }
};

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("access");

        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
    }
});

export default baseQuery;
