/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useFetchCurrentUser, usePing } from "../api/react-query/usersApi";
import { Box, CircularProgress } from "@mui/material";

const LoginGuard = () => {
  const navigate = useNavigate();
  const { error: errorPing } = usePing();
  const { data: currentUser, isLoading } = useFetchCurrentUser();
  console.log("current user react query", currentUser);

  const location = useLocation();

  console.log("pathname", location.pathname);

  const getPageName = (pathname = "") => {
    const name = pathname.slice(1).replace(/\/.*$/, "");
    // If match is found, return the first word
    console.log("name", name);
    return name;
  };

  useEffect(() => {
    if (errorPing) {
        toast.error("You are not logged in", { id: "not_logged_in" });
        navigate("/login");
    }
  }, [errorPing, navigate]);

  useEffect(() => {
    if (currentUser) {
      // check access permission to page
      const page = getPageName(location.pathname);
      const profile = currentUser?.profile;

      const operationsPages = ["trains", "vessels", "stockpiles", "mines", "grades", "specifications", "certificates"];
      const salesPages = ["customers", "contracts", "prices", "forward-curves"];
      const toolsPages = ["blending"];

      if (operationsPages.includes(page) && profile?.hasOperationsAccess === "none") {
        console.log("no access to operations!");
        navigate("/");
      } else if (salesPages.includes(page) && profile?.hasSalesAccess === "none") {
        console.log("no access to sales!");
        navigate("/");
      } else if (toolsPages.includes(page) && profile?.hasToolsAccess === "none") {
        console.log("no access to tools!");
        navigate("/");
      } else if (page === "users" && profile?.role !== "admin") {
        console.log("no access to users!");
        navigate("/");
      }
    }
  }, [currentUser, navigate, location.pathname]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoginGuard;
