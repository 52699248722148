import { Suspense, lazy } from "react";
import NavBarLayout from "./components/NavBarLayout";
import LoginGuard from "./components/LoginGuard";
import LoadingScreen from "./components/LoadingScreen";

const Loadable = (Component) => (props) =>
  (
    // <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
    // </ErrorBoundary>
  );

const Home = Loadable(lazy(() => import("./pages/Home")));
const Login = Loadable(lazy(() => import("./pages/Login")));
const LoginLink = Loadable(lazy(() => import("./pages/LoginLink")));
const Users = Loadable(lazy(() => import("./pages/Users")));
const Certificates = Loadable(lazy(() => import("./pages/Certificates")));
const Customers = Loadable(lazy(() => import("./pages/Customers")));
const Contracts = Loadable(lazy(() => import("./pages/Contracts")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const Vessels = Loadable(lazy(() => import("./pages/Vessels")));
const Trains = Loadable(lazy(() => import("./pages/Trains")));
const Mines = Loadable(lazy(() => import("./pages/Mines")));
const Prices = Loadable(lazy(() => import("./pages/Prices")));
const SalesReport = Loadable(lazy(() => import("./pages/SalesReport")));
const Blending = Loadable(lazy(() => import("./pages/Blending")));
const Grades = Loadable(lazy(() => import("./pages/Grades")));
const ForwardCurves = Loadable(lazy(() => import("./pages/ForwardCurves")));
const Specifications = Loadable(lazy(() => import("./pages/Specifications")));
const Stockpiles = Loadable(lazy(() => import("./pages/Stockpiles")));
const StockpileDetail = Loadable(lazy(() => import("./pages/StockpileDetail")));

const routes = [
  {
    path: "/",
    element: (
      <>
        <LoginGuard />
        <NavBarLayout />
      </>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "certificates",
        element: <Certificates />,
      },
      {
        path: "blending",
        element: <Blending />,
      },
      {
        path: "grades",
        element: <Grades />,
      },
      {
        path: "specifications",
        element: <Specifications />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "contracts",
        element: <Contracts />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "vessels",
        element: <Vessels />,
      },
      {
        path: "trains",
        element: <Trains />,
      },
      {
        path: "mines",
        element: <Mines />,
      },
      {
        path: "prices",
        element: <Prices />,
      },
      {
        path: "forward-curves",
        element: <ForwardCurves />,
      },
      {
        path: "sales",
        element: <SalesReport />,
      },
      {
        path: "stockpiles",
        element: <Stockpiles />,
      },
      {
        path: "stockpiles/:id",
        element: <StockpileDetail />,
        exact: true,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "login-link/:token",
    element: <LoginLink />,
    exact: true,
  },
];

export default routes;
