/* eslint-disable no-unused-vars */
import { useState, Fragment } from "react";
import {
  Box,
  Typography,
  AppBar as MuiAppBar,
  CssBaseline,
  Toolbar,
  Divider,
  Button,
  IconButton,
  Drawer as MuiDrawer,
  experimentalStyled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Scrollbar from "./Scrollbar";
import ScrollableContainer from "./ScrollableContainer";
import NavSection from "./NavSection";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import TokenIcon from "@mui/icons-material/Token";
import TrainIcon from "@mui/icons-material/Train";
import TuneIcon from "@mui/icons-material/Tune";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GavelIcon from "@mui/icons-material/Gavel";
import GradingIcon from "@mui/icons-material/Grading";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LandslideIcon from "@mui/icons-material/Landslide";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SellIcon from "@mui/icons-material/Sell";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { useFetchCurrentUserQuery } from "../api/redux/userApi";
import { toast } from "react-hot-toast";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const AppBar = experimentalStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = experimentalStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

const NavBarLayout = observer(() => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // todo: use data from store?
  const { data: currentUser, error, isLoading } = useFetchCurrentUserQuery();
  const profile = currentUser?.profile;

  const sections = [
    {
      // title: "General",
      items: [
        {
          title: "Dashboard",
          path: "/",
          icon: <DashboardIcon />
        },
        {
          title: "Users",
          path: "/users",
          icon: <AdminPanelSettingsIcon />,
          hidden: profile?.role !== "admin",
        },
      ],
    },
    {
      title: "Operations",
      icon: <LandslideIcon />,
      hidden: profile?.hasOperationsAccess === "none",
      items: [
        {
          title: "Grades",
          path: "/grades",
          icon: <CategoryIcon />
        },
        {
          title: "Mines",
          path: "/mines",
          icon: <TokenIcon />,
        },
        {
          title: "Stockpiles",
          path: "/stockpiles",
          icon: <InventoryIcon />,
        },
        {
          title: "Vessels",
          path: "/vessels",
          icon: <DirectionsBoatIcon />,
        },
        {
          title: "Trains",
          path: "/trains",
          icon: <TrainIcon />,
        },
        {
          title: "Certificates",
          path: "/certificates",
          icon: <GradingIcon />,
        },
      ],
    },
    {
      title: "Sales",
      icon: <PointOfSaleIcon />,
      hidden: profile?.hasSalesAccess === "none",
      items: [
        {
          title: "Contracts",
          path: "/contracts",
          icon: <GavelIcon />,
        },
        {
          title: "Customers",
          path: "/customers",
          icon: <PeopleAltIcon />,
        },
        {
          title: "Prices",
          path: "/prices",
          icon: <PriceChangeIcon />,
        },
        {
          title: "Forward Curves",
          path: "/forward-curves",
          icon: <TrendingUpIcon />,
        },
      ],
    },
    {
      title: "Reports",
      icon: <AssessmentIcon />,
      hidden: profile?.hasSalesAccess === "none",
      items: [
        {
          title: "Sales",
          path: "/sales",
          icon: <SellIcon />,
        },
        // {
        //   title: "Quality",
        //   path: "/quality",
        //   icon: <QueryStatsIcon />,
        // },
      ],
    },
    {
      title: "Tools",
      icon: <HomeRepairServiceIcon />,
      hidden: profile?.hasToolsAccess === "none",
      items: [
        {
          title: "Blending",
          path: "/blending",
          icon: <TuneIcon />,
        },
      ],
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "background.light" }}>
        <Toolbar
          sx={{
            backgroundColor: "background.light",
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <img
              src="/icon.png"
              alt="opticoal-icon"
              height="20px"
            />
          </RouterLink>
          <Typography
            component={RouterLink}
            to="/"
            variant="h6"
            color="primary"
            sx={{
              flexGrow: 1,
              textDecoration: "none",
              fontWeight: "700",
              textTransform: "uppercase",
              ml: 1,
            }}
          >
            Opticoal
          </Typography>
          <Button onClick={() => {
            localStorage.clear();
            navigate("/login");
            toast.success("Successfully logged out");
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ScrollableContainer options={{ suppressScrollX: true }}>
          <Box sx={{
            px: 0,
            flexDirection: "column",
            }}
          >
            {sections.map((section) => (
              <Fragment key={section.title}>
                <NavSection
                  pathname={location.pathname}
                  {...section}
                  drawerOpen={open}
                />
                <Divider sx={{ my: 1, display: section.hidden ? "none" : "flex" }} />
              </Fragment>
            ))}
          </Box>
        </ScrollableContainer>
      </Drawer>
      <Box component="main" sx={{ flex: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet />
      </Box>
    </Box>
  );
});

export default NavBarLayout;
