import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery, { handleQueryError } from "./apiConfig";

export const stockpileApi = createApi({
  reducerPath: "stockpileApi",
  baseQuery,
  tagTypes: ["Stockpile", "StockpileLine", "Outage", "Distribution", "Level"],
  endpoints: (builder) => ({
    // Stockpiles
    fetchStockpiles: builder.query({
      query: (params) => ({ url: "coal/stockpile/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Stockpile", id })),
            { type: "Stockpile", id: "LIST" },
            ]
        : [{ type: "Stockpile", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpiles", { queryFulfilled })
    }),
    fetchStockpileById: builder.query({
      query: (id) => `coal/stockpile/${id}/`,
      providesTags: (result) => [{ type: "Stockpile", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile", { queryFulfilled })
    }),
    fetchStockpileLevels: builder.query({
      query: (params) => ({ url: "coal/stockpile/levels/", params }),
      providesTags: () => [{ type: "Level", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile levels", { queryFulfilled })
    }),
    createStockpile: builder.mutation({
      query: (data) => ({ url: "coal/stockpile/", method: "POST", body: data }),
      providesTags: (result, error, arg) => [{ type: "Stockpile", id: arg.id }],
      invalidatesTags: () => [{ type: "Stockpile", id: "LIST" }, { type: "Level", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating stockpile", { queryFulfilled })
    }),
    updateStockpileById: builder.mutation({
      query: (params) => ({ url: `coal/stockpile/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result, error, arg) => [{ type: "Stockpile", id: arg.id }],
      invalidatesTags: (result, error, arg) => [{ type: "Stockpile", id: arg.id }, { type: "Level", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating stockpile", { queryFulfilled })
    }),

    // Stockpile Lines
    fetchAllStockpileLines: builder.query({
      query: (data) => ({ url: "coal/stockpile-line/", data }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "StockpileLine", id })),
            { type: "StockpileLine", id: "LIST" },
            ]
        : [{ type: "StockpileLine", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile lines", { queryFulfilled })
    }),
    fetchStockpileLines: builder.query({
      query: ({ id, params }) => ({ url: `coal/stockpile/${id}/lines/`, params }),
      providesTags: (result) =>
      (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "StockpileLine", id })),
            { type: "StockpileLine", id: "LIST" },
            ]
        : [{ type: "StockpileLine", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile lines", { queryFulfilled })
    }),
    createStockpileLine: builder.mutation({
      query: ({ id, data }) => ({ url: `coal/stockpile/${id}/lines/`, method: "POST", body: data }),
      providesTags: (result) => [{ type: "StockpileLine", id: result.id }],
      invalidatesTags: (result, error, arg) => [
        { type: "Stockpile", id: arg.id },
        { type: "StockpileLine", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating stockpile line", { queryFulfilled })
    }),
    createStockpileOutage: builder.mutation({
      query: ({ id, data }) => ({ url: `coal/stockpile/${id}/outages/`, method: "POST", body: data }),
      invalidatesTags: (result, error, arg) => [
        { type: "Stockpile", id: arg.id },
        { type: "StockpileLine", id: "LIST" },
        { type: "Outage", id: "LIST" },
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating stockpile outage", { queryFulfilled })
    }),
    fetchStockpileLineLevels: builder.query({
      query: ({ id, params }) => ({ url: `coal/stockpile/${id}/levels/`, params }),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile line levels", { queryFulfilled })
    }),
    fetchStockpileDistribution: builder.query({
      query: ({ id, params }) => ({ url: `coal/stockpile/${id}/distribution/`, params }),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile distributions", { queryFulfilled })
    }),
    fetchStockpileDistributions: builder.query({
      query: (params) => ({ url: "coal/stockpile/distributions/", params }),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching stockpile distributions", { queryFulfilled })
    }),
    fetchStockpileTonnageHistory: builder.query({
      query: (params) => ({ url: "coal/stockpile/tonnage-history/", params }),
    }),
    fetchStockpileHistory: builder.query({
      query: ({ id, params }) => ({ url: `coal/stockpile/${id}/history/`, params }),
    }),
  }),
});

// Destructure the generated hooks for the stockpileApi slice
export const {
  useFetchStockpilesQuery,
  useFetchStockpileByIdQuery,
  useFetchStockpileLevelsQuery,
  useCreateStockpileMutation,
  useUpdateStockpileByIdMutation,
  useFetchAllStockpileLinesQuery,
  useFetchStockpileLinesQuery,
  useCreateStockpileLineMutation,
  useCreateStockpileOutageMutation,
  useFetchStockpileLineLevelsQuery,
  useFetchStockpileDistributionQuery,
  useFetchStockpileDistributionsQuery,
  useFetchStockpileTonnageHistoryQuery,
  useFetchStockpileHistoryQuery,
} = stockpileApi;

export default stockpileApi;
