import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./apiConfig";

export const vesselApi = createApi({
    reducerPath: "vesselApi",
    baseQuery,
    tagTypes: ["Vessel", "Detail", "Arrival", "Activity", "Port", "Issue"],
    endpoints: (builder) => ({
      // Fetch all vessels
      fetchVessels: builder.query({
        query: (params) => ({ url: "coal/vessel/", params }),
        providesTags: (result) =>
          (result?.results
          ? [
              ...result.results.map(({ id }) => ({ type: "Vessel", id })),
              { type: "Vessel", id: "LIST" },
              ]
          : [{ type: "Vessel", id: "LIST" }]),
        }),
      // Fetch vessel by ID
      fetchVesselById: builder.query({
        query: (params) => `coal/vessel/${params.id}/`,
        providesTags: (result) => [{ type: "Vessel", id: result.id }],
      }),
      // Fetch vessel positions
      fetchVesselPositions: builder.query({
        query: (params) => ({ url: "coal/vessel/positions/", params }),
      }),
      // Check vessel IMO
      checkVesselImo: builder.query({
        query: (imo) => `coal/vessel/check-imo/?imo=${imo}`,
      }),
      // Fetch vessel arrivals
      fetchVesselArrivals: builder.query({
        query: (params) => ({ url: "coal/vessel/arrivals/", params }),
        providesTags: (result) =>
          (result?.arrivals
          ? [
              ...result.arrivals.map(({ month }) => ({ type: "Arrival", id: month })),
              { type: "Arrival", id: "LIST" },
              ]
          : [{ type: "Arrival", id: "LIST" }]),
        invalidatesTags: () => [{ type: "Arrival", id: "LIST" }],
      }),
      // Run vessel blending
      runVesselBlending: builder.query({
        query: (params) => ({ url: "coal/blending/", params }),
      }),
      // Check blending status
      checkBlendingStatus: builder.query({
        query: (params) => ({ url: "coal/vessel/blending-status/", params }),
      }),
      // Fetch vessel issues
      fetchVesselIssues: builder.query({
        query: (params) => ({ url: "coal/vessel/issues/", params }),
        providesTags: () => [{ type: "Issue", id: "LIST" }],
      }),
      // Fetch vessel detail by ID
      fetchVesselDetailById: builder.query({
        query: (params) => `coal/vessel/${params.id}/detail/`,
        providesTags: (result) => [{ type: "Detail", id: result.id }],
      }),
      // Fetch vessel activity by ID
      fetchVesselActivityById: builder.query({
        query: (id) => `coal/vessel/${id}/activity/`,
        providesTags: (result) => [{ type: "Activity", id: result.id }],
      }),
      // Update vessel position by ID
      updateVesselPositionById: builder.mutation({
        query: (id) => ({ url: `coal/vessel/${id}/update-position/`, method: "GET" }),
        invalidatesTags: (result, error, arg) => [
          { type: "DETAIL", id: arg },
        ],
      }),
      // Create a new vessel
      createVessel: builder.mutation({
        query: (data) => ({ url: "coal/vessel/", method: "POST", body: data }),
        providesTags: (result) => [{ type: "Vessel", id: result.id }],
        invalidatesTags: () => [
          { type: "Vessel", id: "LIST" },
          { type: "Arrival", id: "LIST" },
          { type: "Issue", id: "LIST" },
        ],
      }),
      // Update vessel by ID
      updateVesselById: builder.mutation({
        query: (params) => ({ url: `coal/vessel/${params.id}/`, method: "PATCH", body: params }),
        providesTags: (result) => [{ type: "Vessel", id: result.id }],
        invalidatesTags: (result) => [
            { type: "Vessel", id: result.id },
            { type: "Activity", id: result.id },
            { type: "Arrival", id: "LIST" },
            { type: "Issue", id: "LIST" },
        ],
      }),
      // Generate vessels
      generateVessels: builder.mutation({
        query: (params) => ({ url: "coal/vessel/generate/", params }),
        invalidatesTags: () => [
            { type: "Vessel", id: "LIST" },
            { type: "Arrival", id: "LIST" },
            { type: "Activity", id: "LIST" },
            { type: "Issue", id: "LIST" },
        ],
      }),
      // Create certificate for vessel
      createVesselCertificate: builder.mutation({
        query: (data) => {
          const body = new FormData();
          Object.entries(data).forEach(([key, value]) => {
            body.append(key, value);
          });

          return ({
          url: "coal/certificate/",
          method: "POST",
          formData: true,
          body,
        });
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Vessel", id: arg.vessel },
          { type: "Detail", id: arg.vessel },
        ],
      }),
      // PORTS
      // Fetch all ports
      fetchPorts: builder.query({
        query: (params) => ({ url: "coal/port/", params }),
        providesTags: (result) =>
          (result?.results
          ? [
              ...result.results.map(({ id }) => ({ type: "Vessel", id })),
              { type: "Vessel", id: "LIST" },
              ]
          : [{ type: "Vessel", id: "LIST" }]),
        }),
      // Fetch port by ID
      fetchPortById: builder.query({
        query: (id) => `coal/port/${id}/`,
        providesTags: (result) => [{ type: "Port", id: result.id }],
      }),
      // Create a new port
      createPort: builder.mutation({
        query: (data) => ({ url: "coal/port/", method: "POST", body: data }),
        providesTags: (result) => [{ type: "Port", id: result.id }],
        invalidatesTags: () => [
          { type: "Port", id: "LIST" },
        ],
      }),
      // Update port by ID
      updatePortById: builder.mutation({
        query: (params) => ({ url: `coal/port/${params.id}/`, method: "PATCH", body: params }),
        providesTags: (result) => [{ type: "Port", id: result.id }],
        invalidatesTags: (result) => [
            { type: "Port", id: result.id },
        ],
      }),
  }),
});

// Destructure the generated hooks for the vesselApi slice
export const {
  // Vessels
  useFetchVesselsQuery,
  useFetchVesselByIdQuery,
  useFetchVesselPositionsQuery,
  useCheckVesselImoQuery,
  useFetchVesselArrivalsQuery,
  useRunVesselBlendingQuery,
  useCheckBlendingStatusQuery,
  useFetchVesselIssuesQuery,
  useFetchVesselDetailByIdQuery,
  useFetchVesselActivityByIdQuery,
  useUpdateVesselPositionByIdMutation,
  useCreateVesselMutation,
  useUpdateVesselByIdMutation,
  useGenerateVesselsMutation,
  useCreateVesselCertificateMutation,
  // Ports
  useFetchPortsQuery,
  useFetchPortByIdQuery,
  useCreatePortMutation,
  useUpdatePortByIdMutation,
} = vesselApi;

export default vesselApi;
