import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./apiConfig";

export const certificateApi = createApi({
  reducerPath: "certificateApi",
  baseQuery,
  tagTypes: ["Certificate"],
  endpoints: (builder) => ({
    // Certificates
    fetchCertificates: builder.query({
      query: (params) => ({ url: "coal/certificate/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Certificate", id })),
            { type: "Certificate", id: "LIST" },
            ]
        : [{ type: "Certificate", id: "LIST" }]),
    }),
    fetchCertificateById: builder.query({
      query: (id) => `coal/certificate/${id}/`,
      providesTags: (result, error, arg) => [{ type: "Certificate", id: arg.id }],
    }),
    createCertificate: builder.mutation({
      query: (data) => ({ url: "coal/certificate/", method: "POST", body: data }),
      providesTags: (result, error, arg) => [{ type: "Certificate", id: arg.id }],
    }),
    updateCertificateById: builder.mutation({
      query: (params) => ({ url: `coal/certificate/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result, error, arg) => [{ type: "Certificate", id: arg.id }],
      invalidatesTags: (result, error, arg) => [{ type: "Certificate", id: arg.id }],
    }),
  }),
});

// Destructure the generated hooks for the certificateApi slice
export const {
  useFetchCertificatesQuery,
  useFetchCertificateByIdQuery,
  useCreateCertificateMutation,
  useUpdateCertificateByIdMutation,
} = certificateApi;

export default certificateApi;
