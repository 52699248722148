import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchUsers,
  fetchUserById,
  fetchCurrentUser,
  createUser,
  updateUserById,
  sendLoginLink,
  loginWithLink,
  ping,
  tokenVerify,
} from "../users";

// Fetch all users
export const useFetchUsers = (params) =>
  useQuery({
    queryKey: ["Users", params],
    queryFn: () => fetchUsers(params),
    keepPreviousData: true,
  });

// Fetch user by ID
export const useFetchUserById = (id) =>
  useQuery({
    queryKey: ["User", id],
    queryFn: () => fetchUserById(id),
    enabled: !!id,
  });

// Fetch current user
export const useFetchCurrentUser = () =>
  useQuery({
    queryKey: ["CurrentUser"],
    queryFn: fetchCurrentUser,
    onSuccess: (data) => {
      console.log("User fetched successfully", data);
    },
    onError: (error) => {
      console.error("Error fetching user", error);
    },
  });

// Create a new user
export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createUser,
    onSuccess: (newUser) => {
      queryClient.invalidateQueries(["Users"]);
      queryClient.setQueryData(["User", newUser.id], newUser);
    },
  });
};

// Update user by ID
export const useUpdateUserById = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUserById,
    onSuccess: (updatedUser) => {
      queryClient.invalidateQueries(["Users"]);
      queryClient.setQueryData(["User", updatedUser.id], updatedUser);
    },
  });
};

// Send login link
export const useSendLoginLink = () =>
  useMutation({
    mutationFn: sendLoginLink,
    onSuccess: (response) => {
      console.log("Login link sent successfully", response);
    },
  });

// Login with link
export const useLoginWithLink = (token) =>
  useQuery({
    queryKey: ["LoginWithLink", token],
    queryFn: () => loginWithLink(token),
    enabled: !!token,
  });

// Ping API
export const usePing = () =>
  useQuery({
    queryKey: ["Ping"],
    queryFn: ping,
    retry: false, // Disable retries to prevent additional calls
    cacheTime: 0, // Optional: Set to 0 to force fresh data each time
    staleTime: Infinity, // Prevent re-fetching unless explicitly invalidated
  });

// Token verification
export const useTokenVerify = () =>
  useMutation({
    mutationFn: tokenVerify,
    onSuccess: (response) => {
      console.log("Token verified successfully", response);
    },
    onError: (error) => {
      console.error("Token verification failed", error);
    },
  });
