/* eslint-disable no-unused-vars */
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./apiConfig";
import { setUser, setAccessToken } from "./slices/authSlice";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery,
    tagTypes: ["User", "CurrentUser"],
    endpoints: (builder) => ({
      // Fetch all users
      fetchUsers: builder.query({
        query: (params) => ({ url: "users/user/", params }),
        providesTags: (result) =>
          (result?.results
          ? [
              ...result.results.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
              ]
          : [{ type: "User", id: "LIST" }]),
        }),
      // Fetch user by ID
      fetchUserById: builder.query({
        query: (id) => `users/user/${id}`,
        providesTags: (result) => [{ type: "User", id: result.id }],
      }),

      // Fetch current user
      fetchCurrentUser: builder.query({
        query: () => "users/current/",
        providesTags: (result) => [{ type: "CurrentUser", id: result.id }],
        onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            if (!data) return;
            console.log("FETCHED USER, SAVE:", data);
            // Success, set the user in state
            dispatch(setUser(data));
            console.log("done dispatching user");
          } catch (error) {
            // Failure, reset the user back to null
            console.log("USER SAVE ERROR", error);
            dispatch(setUser(null));
          }
        },
      }),

      // Create a new user
      createUser: builder.mutation({
        query: (data) => ({ url: "users/user/", method: "POST", body: data }),
        providesTags: (result) => [{ type: "User", id: result.id }],
        invalidatesTags: () => [
          { type: "User", id: "LIST" },
          { type: "Activity", id: "LIST" },
        ],
      }),
      // Update user by ID
      updateUserById: builder.mutation({
        query: (params) => ({ url: `users/user/${params.id}/`, method: "PATCH", body: params }),
        providesTags: (result) => [{ type: "User", id: result.id }],
        invalidatesTags: (result) => [
            { type: "User", id: result.id },
            { type: "CurrentUser", id: result.id },
            { type: "Activity", id: result.id },
        ],
      }),

      // LOGIN
      sendLoginLink: builder.query({ query: (email) => ({ url: "users/email-login/", method: "POST", body: { email } }) }),
      loginWithLink: builder.query({ query: (token) => `users/link-login/?token=${token}` }),
      ping: builder.query({ query: () => "users/login/ping/" }),
      tokenVerify: builder.mutation({ query: (token) => ({ url: "users/login/verify/", body: { token } }) }),
    })
});

export const {
  useFetchUsersQuery,
  useFetchUserByIdQuery,
  useFetchCurrentUserQuery,
  useCreateUserMutation,
  useUpdateUserByIdMutation,
  // LOGIN
  useSendLoginLinkQuery,
  useLoginWithLinkQuery,
  usePingQuery,
  useTokenVerifyMutation,
} = userApi;

export default userApi;
