import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery, { handleQueryError } from "./apiConfig";

export const contractApi = createApi({
  reducerPath: "contractApi",
  baseQuery,
  tagTypes: ["Contract", "Customer", "ContractActivity", "CustomerActivity"],
  endpoints: (builder) => ({
    // Customers
    fetchCustomers: builder.query({
      query: (params) => ({ url: "contracts/customer/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Customer", id })),
            { type: "Customer", id: "LIST" },
            ]
        : [{ type: "Customer", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching customers", { queryFulfilled })
    }),
    fetchCustomerById: builder.query({
      query: (id) => `contracts/customer/${id}/`,
      providesTags: (result) => [{ type: "Customer", id: result.id }],
      invalidatesTags: (result) => [{ type: "Customer", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching customer", { queryFulfilled })
    }),
    fetchCustomerAllActivity: builder.query({
      query: (params) => ({ url: "contracts/customer/all-activity/", params }),
      providesTags: () => [{ type: "CustomerActivity", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching customer activity", { queryFulfilled })
    }),
    createCustomer: builder.mutation({
      query: (data) => ({ url: "contracts/customer/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "Customer", id: result.id }],
      invalidatesTags: () => [
        { type: "Customer", id: "LIST" },
        // to update activity logs
        { type: "CustomerActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating customer", { queryFulfilled })
    }),
    updateCustomerById: builder.mutation({
      query: (params) => ({ url: `contracts/customer/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "Customer", id: result.id }],
      invalidatesTags: (result) => [
        { type: "Customer", id: result.id },
        // to update activity logs
        { type: "CustomerActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating customer", { queryFulfilled })
    }),

    // Contracts
    fetchContracts: builder.query({
      query: (params) => ({ url: "contracts/contract/", params }),
      providesTags: (result) =>
        (result?.results
          ? [
              ...result.results.map(({ id }) => ({ type: "Contract", id })),
              { type: "Contract", id: "LIST" },
              ]
          : [{ type: "Contract", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching contracts", { queryFulfilled })
    }),
    fetchContractById: builder.query({
      query: (params) => `contracts/contract/${params.id}/`,
      providesTags: (result) => [{ type: "Contract", id: result.id }],
      invalidatesTags: (result) => [{ type: "Contract", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching contract", { queryFulfilled })
    }),
    fetchContractActivityById: builder.query({
      query: (id) => `contracts/contract/${id}/activity/`,
      providesTags: (result) => [{ type: "ContractActivity", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching contract activity", { queryFulfilled })
    }),
    createContract: builder.mutation({
      query: (data) => ({ url: "contracts/contract/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "Contract", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating contract", { queryFulfilled })
    }),
    updateContractById: builder.mutation({
      query: (params) => ({ url: `contracts/contract/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "Contract", id: result.id }],
      invalidatesTags: (result) => [
        { type: "Contract", id: result.id },
        // to update contract activity
        { type: "ContractActivity", id: result.id }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating contract", { queryFulfilled })
    }),
    // Fetch YTD SalesReport
    fetchYTDSalesReport: builder.query({
      query: (params) => ({ url: "coal/shipment/sales-report/", params }),
    }),
    // Fetch MTD SalesReport
    fetchMTDSalesReport: builder.query({
      query: (params) => ({ url: "coal/shipment/mtd-sales-report/", params }),
    }),
  }),
});

// Destructure the generated hooks for the contractApi slice
export const {
  useFetchCustomersQuery,
  useFetchCustomerByIdQuery,
  useFetchCustomerAllActivityQuery,
  useCreateCustomerMutation,
  useUpdateCustomerByIdMutation,
  useFetchContractsQuery,
  useFetchContractByIdQuery,
  useFetchContractActivityByIdQuery,
  useCreateContractMutation,
  useUpdateContractByIdMutation,
  // Sales Report
  useFetchMTDSalesReportQuery,
  useFetchYTDSalesReportQuery,
} = contractApi;

export default contractApi;
