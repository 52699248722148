// store.js
import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./userApi";
import { vesselApi } from "./vesselApi";
import { trainApi } from "./trainApi";
import { certificateApi } from "./certificateApi";
import { coalApi } from "./coalApi";
import { contractApi } from "./contractApi";
import { stockpileApi } from "./stockpileApi";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import authReducer from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userApi: userApi.reducer,
    vesselApi: vesselApi.reducer,
    trainApi: trainApi.reducer,
    certificateApi: certificateApi.reducer,
    coalApi: coalApi.reducer,
    contractApi: contractApi.reducer,
    stockpileApi: stockpileApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    userApi.middleware,
    vesselApi.middleware,
    trainApi.middleware,
    certificateApi.middleware,
    coalApi.middleware,
    contractApi.middleware,
    stockpileApi.middleware,
    rtkQueryErrorLogger,
  ),
});

export default store;
