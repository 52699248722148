import routes from "./routes";
import { useRoutes } from "react-router";
import { createCustomTheme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { THEMES } from "./constants";
import AppToaster from "./components/AppToaster";
import "./global.css";

function App() {
  const theme = createCustomTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
  });
  const content = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppToaster />
      {content}
    </ThemeProvider>
  );
}

export default App;
