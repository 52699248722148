/* eslint-disable no-unused-vars */
import { MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const message = "data" in action.error
      ? action.error.data.message
      : action.error.message;

    console.warn("error warning:", message);
    // toast.error(`Error: ${message}`);
  }

  return next(action);
};
