import {
    Box,
    experimentalStyled,
  } from "@mui/material";

const ScrollableContainer = experimentalStyled(Box)(() => ({
    scrollbarGutter: "stable",
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
    },
    "&::-webkit-scrollbar-button": {
      height: 0,
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8F9BB33D",
      border: "2px solid transparent",
      borderRadius: "10px",
    },
  }));

export default ScrollableContainer;
