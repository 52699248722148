// authSlice.js
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accessToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log("user state before, action:", state, action);
      state.user = action.payload;
      console.log("user state after:", state.user);
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    logOut: () => initialState
  },
});

export const { setUser, setAccessToken, logOut } = authSlice.actions;

export default authSlice.reducer;

// selectors
const selectAuthState = (state) => state.auth;

export const selectUser = createSelector(
  selectAuthState,
  (auth) => auth?.user
);
// export const selectUser = (state) => state.auth?.user;
// todo: use this
export const selectAccessToken = (state) => state.auth?.accessToken;
