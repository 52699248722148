import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery, { handleQueryError } from "./apiConfig";

export const coalApi = createApi({
  reducerPath: "coalApi",
  baseQuery,
  tagTypes: ["Grade", "Mine", "MineGrade", "GradeActivity"], // Updated tag types
  endpoints: (builder) => ({
    // Grades
    fetchGrades: builder.query({
      query: (params) => ({ url: "coal/grade/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Grade", id })),
            { type: "Grade", id: "LIST" },
            ]
        : [{ type: "Grade", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching grades", { queryFulfilled })
    }),
    fetchGradeById: builder.query({
      query: (id) => `coal/grade/${id}/`,
      providesTags: (result) => [{ type: "Grade", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching grade", { queryFulfilled })
    }),
    fetchGradeAllActivity: builder.query({
      query: (params) => ({ url: "coal/grade/all-activity/", params }),
      providesTags: () => [{ type: "GradeActivity", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching grade activity", { queryFulfilled })
    }),
    createGrade: builder.mutation({
      query: (data) => ({ url: "coal/grade/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "Grade", id: result.id }],
      invalidatesTags: () => [
        { type: "Grade", id: "LIST" },
        { type: "GradeActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating grade", { queryFulfilled })
    }),
    updateGradeById: builder.mutation({
      query: (params) => ({ url: `coal/grade/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "Grade", id: result.id }],
      invalidatesTags: (result) => [
        { type: "Grade", id: result.id },
        { type: "GradeActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating grade", { queryFulfilled })
    }),

    // MineGrades
    fetchMineGrades: builder.query({
      query: (params) => ({ url: "coal/mine-grade/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "MineGrade", id })),
            { type: "MineGrade", id: "LIST" },
            ]
        : [{ type: "MineGrade", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching mine grades", { queryFulfilled })
    }),
    fetchMineGradeById: builder.query({
      query: (id) => `coal/mine-grade/${id}/`,
      providesTags: (result) => [{ type: "MineGrade", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching mine grade", { queryFulfilled })
    }),
    // fetchMineGradeAllActivity: builder.query({
    //   query: (params) => ({ url: "coal/grade/all-activity/", params }),
    //   providesTags: () => [{ type: "MineGradeActivity", id: "LIST" }],
    // }),
    createMineGrade: builder.mutation({
      query: (data) => ({ url: "coal/mine-grade/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "MineGrade", id: result.id }],
      invalidatesTags: () => [
        { type: "MineGrade", id: "LIST" },
        // { type: "MineGradeActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating mine grade", { queryFulfilled })
    }),
    updateMineGradeById: builder.mutation({
      query: (params) => ({ url: `coal/mine-grade/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "MineGrade", id: result.id }],
      invalidatesTags: (result) => [
        { type: "MineGrade", id: result.id },
        // { type: "MineGradeActivity", id: "LIST" }
      ],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating mine grade", { queryFulfilled })
    }),

    // Mines
    fetchMines: builder.query({
      query: (params) => ({ url: "coal/mine/", params }),
      providesTags: (result) =>
        (result?.results
        ? [
            ...result.results.map(({ id }) => ({ type: "Mine", id })),
            { type: "Mine", id: "LIST" },
            ]
        : [{ type: "Mine", id: "LIST" }]),
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching mines", { queryFulfilled })
    }),
    fetchMineById: builder.query({
      query: (id) => `coal/mine/${id}/`,
      providesTags: (result) => [{ type: "Mine", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error fetching mine", { queryFulfilled })
    }),
    createMine: builder.mutation({
      query: (data) => ({ url: "coal/mine/", method: "POST", body: data }),
      providesTags: (result) => [{ type: "Mine", id: result.id }],
      invalidatesTags: () => [{ type: "Mine", id: "LIST" }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error creating mine", { queryFulfilled })
    }),
    updateMineById: builder.mutation({
      query: (params) => ({ url: `coal/mine/${params.id}/`, method: "PATCH", body: params }),
      providesTags: (result) => [{ type: "Mine", id: result.id }],
      invalidatesTags: (result) => [{ type: "Mine", id: result.id }],
      onQueryStarted: (args, { queryFulfilled }) =>
      handleQueryError("Error updating mine", { queryFulfilled })
    }),
  }),
});

// Destructure the generated hooks for the coalApi slice
export const {
  // grades
  useFetchGradesQuery,
  useFetchGradeByIdQuery,
  useFetchGradeAllActivityQuery,
  useCreateGradeMutation,
  useUpdateGradeByIdMutation,
  // mine grades
  useFetchMineGradesQuery,
  useFetchMineGradeByIdQuery,
  // useFetchMineGradeAllActivityQuery,
  useCreateMineGradeMutation,
  useUpdateMineGradeByIdMutation,
  // mines
  useFetchMinesQuery,
  useFetchMineByIdQuery,
  useCreateMineMutation,
  useUpdateMineByIdMutation,
} = coalApi;

export default coalApi;
