import base from "./base";

export const fetchUsers = (params) => base.get("users/user/", { params });
export const fetchUserById = (id) => base.get(`users/user/${id}`);
export const fetchCurrentUser = () => base.get("users/current/");
export const createUser = (data) => base.post("users/user/", data);
export const updateUserById = ({ id, ...data }) => base.patch(`users/user/${id}/`, data);
export const sendLoginLink = (data) => base.post("users/email-login/", data);
export const loginWithLink = (token) => base.get(`users/link-login/?token=${token}`);
export const ping = () => base.get("users/login/ping/");
export const tokenVerify = (token) => base.post("users/login/verify/", { token });
